import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import parkguardLogo from 'assets/images/park-guard-simple-logo.png'
import {
  selectIsLoggedIn,
  clearCurrentUser,
} from 'redux-slices/currentUserSlice'
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Button,
} from '@nextui-org/react'

export const AppNavBar = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    localStorage.removeItem('accessToken')
    dispatch(clearCurrentUser())
    navigate('/')
  }

  return (
    <Navbar isBordered className="bg-navbarBg flex w-full" maxWidth="xl">
      <NavbarBrand className="flex justify-start">
        <RouterLink to="/">
          <div className="flex items-center">
            <img
              src={parkguardLogo}
              alt="Logo"
              className="w-30 h-14 bg-transparent"
            />
            <span className="text-sm font-bold text-[#5f5e59]">
              Park Guard Coverage Hub
            </span>
          </div>
        </RouterLink>
      </NavbarBrand>

      <NavbarContent justify="end">
        {isLoggedIn && (
          <NavbarItem>
            <Button
              className="bg-gray-950 text-white"
              onClick={handleLogout}
              variant="solid"
            >
              Logout
            </Button>
          </NavbarItem>
        )}
      </NavbarContent>
    </Navbar>
  )
}
