import React from 'react'
import { UsaStates } from 'usa-states'
import { FormDateRangePicker } from 'components/generics/date-range-picker'
import { damageTypes } from 'components/partner/claim-forms/form-constants'
import { FormInput } from 'components/partner/claim-forms/form-generics/FormInputField'
import { FormSelect } from 'components/partner/claim-forms/form-generics/FormSelectField'
import { FormDatePicker } from 'components/partner/claim-forms/form-generics/FormDatePicker'

const VehicleDetailsSection = ({
  control,
  register,
  errors,
  isLicenseInfoAvailable,
}) => {
  const usStates = new UsaStates()
  const stateItems = usStates.states.map(state => ({
    value: state.abbreviation,
    label: state.name,
  }))
  return (
    <section>
      <h2 className="text-xl font-semibold text-gray-700 border-b border-gray-200 pb-3 mb-4">
        Vehicle Details
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 col-span-2">
          <FormInput
            label="Car Make"
            name="carInformation.make"
            register={register}
            errors={errors}
            placeholder="e.g., Honda"
          />
          <FormInput
            label="Car Model"
            name="carInformation.model"
            register={register}
            errors={errors}
            placeholder="e.g., Civic"
          />
          <FormInput
            label="Car Year"
            name="carInformation.year"
            type="number"
            min="1951"
            max={new Date().getFullYear()}
            pattern="\d*"
            register={register}
            errors={errors}
            placeholder="e.g., 2023"
          />
        </div>
        {isLicenseInfoAvailable && (
          <>
            <FormSelect
              control={control}
              name="licensePlateState"
              label="License Plate State"
              placeholder="Select a state"
              items={stateItems}
              errors={errors}
            />
            <FormInput
              label="License Plate Number"
              name="licensePlateInfo"
              register={register}
              errors={errors}
              maxLength={7}
              placeholder="e.g., ABC1234"
            />
          </>
        )}

        <FormInput
          label="Phone Number"
          name="phoneNumber"
          type="tel"
          maxLength="11"
          placeholder="123-456-7890"
          register={register}
          errors={errors}
        />
        <FormDateRangePicker
          control={control}
          name="dateParked"
          label="Parking Date Range"
          errors={errors}
          disabled={false}
        />
        <FormSelect
          control={control}
          name="typeOfDamage"
          label="Type of Damage"
          placeholder="Select damage type"
          items={damageTypes}
          errors={errors}
        />
        <FormDatePicker
          control={control}
          name="dateDamageDiscovered"
          label="Date Damage Discovered"
          errors={errors}
        />
      </div>
    </section>
  )
}

export default VehicleDetailsSection
