import React from 'react'
import { FormTextarea } from 'components/partner/claim-forms/form-generics/FormTextAreaField'

export const ClaimDescriptionSection = ({ register, errors }) => {
  return (
    <section>
      <h2 className="text-xl font-semibold text-gray-700 border-b border-gray-200 pb-3 mb-4">
        Claim Description
      </h2>
      <FormTextarea
        label="Description of Damage or Loss"
        name="claim_description"
        placeholder="Provide as much information as possible regarding the theft or damage being reported"
        register={register}
        errors={errors}
        minRows={4}
        maxRows={10}
      />
    </section>
  )
}
