import { useForm } from 'react-hook-form'
import { formatDateRange } from 'helpers'
import { usePagination } from 'hooks/usePagination'
import { Spinner, Tooltip } from '@nextui-org/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { getAllReports } from 'services/partnerService'
import { dateRangeSchema } from 'validations/dateRangeSchema'
import React, { useCallback, useMemo, useState } from 'react'
import { formatDataDateByKey, formatDataForTable } from 'helpers'
import { CloudArrowDown, ArrowsClockwise } from '@phosphor-icons/react'
import { DateRangeFilterForm } from '../customers-info-tab/DateRangeFilterForm'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'
import {
  columnsToDisplay,
  filterSubmissionOptions,
} from './report-downoad-tab-constant'

export const PartnerReportsDownloadTab = () => {
  const [selectedFilter, setSelectedFilter] = useState(null)
  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleFilter,
    handleDateRange,
  } = usePagination(getAllReports)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dateRangeSchema),
    defaultValues: { dateRange: [null, null] },
  })

  const tableData = useMemo(() => {
    const formattedTable = formatDataForTable(data, columnsToDisplay)
    return {
      ...formattedTable,
      data: formatDataDateByKey(formattedTable.data, 'created_at'),
    }
  }, [data])

  const renderActionsColumn = useCallback(
    form => (
      <div className="flex gap-3 justify-center">
        {form?.status === 'ready' ? (
          <Tooltip content="Download">
            <span className="cursor-pointer">
              <a href={`${form.url}`} target="_blank" rel="noopener noreferrer">
                <CloudArrowDown className="h-4 w-4" />
              </a>
            </span>
          </Tooltip>
        ) : (
          <Tooltip content="Pending, Click to Refresh Page">
            <button
              onClick={() => window.location.reload()}
              className="cursor-pointer flex items-center"
            >
              <ArrowsClockwise className="h-4 w-4" />
            </button>
          </Tooltip>
        )}
      </div>
    ),
    [],
  )

  const handleFilterChange = selectedOption => {
    setSelectedFilter(
      selectedOption?.value === selectedFilter ? '' : selectedOption?.value,
    )
    handleFilter(selectedOption?.value || '')
  }

  const onDateRangeSubmit = data => {
    const { date_start, date_end } = formatDateRange(data.dateRange)
    handleDateRange(date_start, date_end)
  }

  const onDateRangeCancel = () => {
    setValue('dateRange', [null, null])
    handleDateRange(null, null)
  }

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex items-center">
            <div className="basis-8/12">
              <h2 className="text-2xl text-gray-600">Reports Data</h2>
            </div>
            <div className="basis-1/6 mx-3">
              <FilterDropdown
                options={filterSubmissionOptions}
                value={selectedFilter}
                onChange={handleFilterChange}
              />
            </div>
            <DateRangeFilterForm
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              onDateRangeSubmit={onDateRangeSubmit}
              onDateRangeCancel={onDateRangeCancel}
              watch={watch}
            />
          </div>
          <DefaultTableComponent
            columns={tableData.columns}
            data={tableData.data}
            renderActions={renderActionsColumn}
            columnsExclude={['ID', 'URL']}
          />
        </div>
      )}

      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
    </>
  )
}
