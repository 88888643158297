import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FormCheckbox } from '../form-generics/FormCheckboxField'
import {
  claimFormCheckboxOptions,
  claimFormUserInstructions,
} from '../form-constants'

export const TermsSection = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="grid grid-cols-1 gap-6 py-4">
      <FormCheckbox control={control} errors={errors} name="legalFormClause">
        <span className="text-xs text-gray-500 text-justify">
          {claimFormCheckboxOptions.legalFormClause}
        </span>
      </FormCheckbox>

      <FormCheckbox control={control} errors={errors} name="termsClause">
        <span className="text-xs text-gray-500 text-justify">
          <span
            className="text-xs text-gray-500 text-justify"
            dangerouslySetInnerHTML={{
              __html: claimFormCheckboxOptions.termsAndConditions,
            }}
          />
        </span>
      </FormCheckbox>

      <div className="px-3">
        <span className="text-xs text-gray-500 text-justify">
          {claimFormUserInstructions}
        </span>
      </div>
    </div>
  )
}
