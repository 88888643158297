import { useForm } from 'react-hook-form'
import { formatDateRange } from 'helpers'
import { Article } from '@phosphor-icons/react'
import { usePagination } from 'hooks/usePagination'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToastMessage } from 'hooks/useToastMessage'
import React, { useCallback, useMemo, useState } from 'react'
import { dateRangeSchema } from 'validations/dateRangeSchema'
import { CloudArrowDown, Upload } from '@phosphor-icons/react'
import { getAllMonthlyReports } from 'services/partnerService'
import { formatDataDateByKey, formatDataForTable } from 'helpers'
import { InformationModal } from 'components/generics/info-modal'
import { handleUploadReport } from './monthly-report-tab-helpers'
import { Spinner, Tooltip, Button, Input } from '@nextui-org/react'
import { DateRangeFilterForm } from '../customers-info-tab/DateRangeFilterForm'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'
import {
  columnsToDisplay,
  filterSubmissionOptions,
  typeSubmissionOptions,
  uploadReportModalActions,
} from './monthly-report-tab-constant'

export const MonthlyReportsTab = () => {
  const { showToast } = useToastMessage()
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [csvFile, setCsvFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)

  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleFilter,
    handleDateRange,
  } = usePagination(getAllMonthlyReports)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dateRangeSchema),
    defaultValues: { dateRange: [null, null] },
  })

  const tableData = useMemo(() => {
    const formattedTable = formatDataForTable(data, columnsToDisplay)
    return {
      ...formattedTable,
      data: formatDataDateByKey(formattedTable.data, 'created_at'),
    }
  }, [data])

  const renderActionsColumn = useCallback(
    form => (
      <div className="flex gap-3 justify-center">
        <Tooltip content="Download Monthly Report">
          <span className="cursor-pointer">
            <a href={`${form.url}`} target="_blank" rel="noopener noreferrer">
              <CloudArrowDown className="h-4 w-4" />
            </a>
          </span>
        </Tooltip>
      </div>
    ),
    [],
  )

  const handleFilterChange = selectedOption => {
    setSelectedFilter(
      selectedOption?.value === selectedFilter ? '' : selectedOption?.value,
    )
    handleFilter(selectedOption?.value || '')
  }

  const handleTypeChange = selectedOption => {
    setSelectedType(
      selectedOption?.value === selectedType ? '' : selectedOption?.value,
    )
  }

  const onDateRangeSubmit = data => {
    const { date_start, date_end } = formatDateRange(data.dateRange)
    handleDateRange(date_start, date_end)
  }

  const onDateRangeCancel = () => {
    setValue('dateRange', [null, null])
    handleDateRange(null, null)
  }

  const handleUploadClick = () => {
    setIsModalOpen(true)
  }

  const handleFileChange = event => {
    setCsvFile(event.target.files[0])
  }

  const handleUploadSubmit = () => {
    handleUploadReport(
      selectedType,
      csvFile,
      setIsUploading,
      handleFilter,
      showToast,
    )
    setCsvFile(null)
    setSelectedType(null)
    setIsModalOpen(false)
  }

  return (
    <>
      {isLoading || isUploading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex justify-start w-full gap-4">
            <Button
              className="py-3 min-w-40 items-center text-xs bg-gray-950 text-white"
              radius="sm"
              variant="solid"
              startContent={<Upload />}
              onClick={handleUploadClick}
            >
              Upload CSV
            </Button>
          </div>
          <div className="flex items-center">
            <div className="basis-8/12">
              <h2 className="text-2xl text-gray-600">Monthly Reports</h2>
            </div>
            <div className="basis-1/6 mx-3">
              <FilterDropdown
                options={filterSubmissionOptions}
                value={selectedFilter}
                onChange={handleFilterChange}
              />
            </div>
            <DateRangeFilterForm
              control={control}
              errors={errors}
              handleSubmit={handleSubmit}
              onDateRangeSubmit={onDateRangeSubmit}
              onDateRangeCancel={onDateRangeCancel}
              watch={watch}
            />
          </div>

          <DefaultTableComponent
            columns={tableData.columns}
            data={tableData.data}
            renderActions={renderActionsColumn}
            columnsExclude={['ID', 'URL']}
          />
        </div>
      )}

      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
      <InformationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title="Upload CSV File"
        body={
          <div className="flex flex-col gap-4">
            <Input
              type="file"
              onChange={handleFileChange}
              accept=".csv"
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100"
            />

            <div className="basis-1/6">
              <FilterDropdown
                options={typeSubmissionOptions}
                value={selectedType}
                onChange={handleTypeChange}
                label="Type"
                Icon={Article}
              />
            </div>
          </div>
        }
        footerActions={uploadReportModalActions(handleUploadSubmit)}
      />
    </>
  )
}
