import React from 'react'
import { claimSubmissionOptions } from './edit-claim-constant'
import { FormSelect } from 'components/partner/claim-forms/form-generics/FormSelectField'
import { FormInput } from 'components/partner/claim-forms/form-generics/FormInputField'
import { FormTextarea } from 'components/partner/claim-forms/form-generics/FormTextAreaField'

export const ClaimProcessSection = ({ control, register, errors, watch }) => {
  const claimStatus = watch('claim_status')
  return (
    <section>
      <h2 className="text-xl font-semibold text-gray-700 border-b border-gray-200 pb-3">
        Claim Process
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
        <FormSelect
          control={control}
          name="claim_status"
          label="Claim Status"
          placeholder="Select Claim Status"
          items={claimSubmissionOptions}
          errors={errors}
        />
        <FormInput
          label="Processor Claim Number"
          name="processor_claim_number"
          register={register}
          errors={errors}
        />
        {claimStatus === 'accepted_and_paid' && (
          <FormInput
            label="Claim Amount Paid"
            name="claim_amount_paid"
            type="number"
            maxLength="11"
            min="0"
            placeholder="$$$"
            register={register}
            errors={errors}
          />
        )}
        {claimStatus === 'declined' && (
          <FormTextarea
            label="Denial Reason"
            name="denial_reason"
            placeholder="Provide a reason for declining the claim"
            register={register}
            errors={errors}
            minRows={2}
          />
        )}
      </div>
    </section>
  )
}
