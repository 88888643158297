import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { formatDateRange } from 'helpers'
import { useNavigate } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/outline'
import { usePagination } from 'hooks/usePagination'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToastMessage } from 'hooks/useToastMessage'
import { Upload, Article } from '@phosphor-icons/react'
import { DateRangeFilterForm } from './DateRangeFilterForm'
import { partnerTabActionsConst } from 'helpers/csv-helpers'
import { handleUploadCsv } from './customer-info-tab-helpers'
import React, { useCallback, useMemo, useState } from 'react'
import { dateRangeSchema } from 'validations/dateRangeSchema'
import { InformationModal } from 'components/generics/info-modal'
import { DownloadCSVButtonGroup } from './DownloadCSVButtonGroup'
import { selectCurrentUser } from 'redux-slices/currentUserSlice'
import { formatDataForTable, formatDataDatesByList } from 'helpers'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { SearchBarComponent } from 'components/generics/search-bar/SearchBarComponent'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { MultiSelectTableDropdown } from 'components/generics/multi-select-table-dropdown'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'
import {
  Spinner,
  Tooltip,
  Button,
  Input,
  Card,
  CardBody,
} from '@nextui-org/react'
import {
  handleAllDataDownload,
  handleCurrentDataDownload,
} from 'helpers/handle-downloads'
import {
  getAllPartnerCustomers,
  getClientDataByIds,
  requestAllClientDownload,
} from 'services/partnerService'
import {
  claimsTabModalActions,
  requestSubmissionModalText,
  claimsWarningModalActions,
  requestSubmissionModalWarning,
  downloadCSVFilename,
  defaultColumnsToDisplay,
  typeSubmissionOptions,
  uploadReportModalActions,
  allColumns,
  dateFields,
} from './customer-info-tab-constants'

export const ClientsInformationTab = () => {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')
  const { currentUser } = useSelector(selectCurrentUser)
  const [columnsToDisplay, setColumnsToDisplay] = useState(
    defaultColumnsToDisplay,
  )
  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleSearch,
    handleDateRange,
  } = usePagination(getAllPartnerCustomers)
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dateRangeSchema),
    defaultValues: { dateRange: [null, null] },
  })

  const tableData = useMemo(() => {
    const formattedData = formatDataDatesByList(data, dateFields)
    return formatDataForTable(formattedData, columnsToDisplay)
  }, [data, columnsToDisplay])

  const { showToast } = useToastMessage()
  const [csvFile, setCsvFile] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedType, setSelectedType] = useState(null)
  const [isAttentionOpen, setIsAttentionOpen] = useState(false)
  const [isDataDownloading, setIsDataDownloading] = useState(false)
  const [isUploadCsvModalOpen, setisUploadCsvModalOpen] = useState(false)

  const handleColumnSelectionChange = selectedColumns => {
    const columnsWithoutActions = selectedColumns.filter(
      col => col !== 'actions',
    )
    setColumnsToDisplay([...columnsWithoutActions, 'actions'])
  }

  const handleTypeChange = selectedOption => {
    setSelectedType(
      selectedOption?.value === selectedType ? '' : selectedOption?.value,
    )
  }

  const handleUploadCsvClick = () => {
    setisUploadCsvModalOpen(true)
  }

  const handleFileChange = event => {
    setCsvFile(event.target.files[0])
  }

  const handleUploadCsvSubmit = async () => {
    handleUploadCsv(
      selectedType,
      csvFile,
      setIsUploading,
      handleSearch,
      showToast,
    )
    setCsvFile(null)
    setSelectedType(null)
    setisUploadCsvModalOpen(false)
  }

  const handleDownloadInitiate = () => {
    setIsAttentionOpen(false)
    handleAllDataDownload(
      setIsDataDownloading,
      'in_review',
      setIsModalOpen,
      requestAllClientDownload,
    )
  }

  const handleGoToReportDownload = () => {
    navigate('/partner/dashboard/reports-download')
  }

  // eslint-disable-next-line no-unused-vars
  const renderActions = useCallback(form => {
    return (
      <div className="flex gap-3 justify-center">
        <Tooltip content="View">
          <span className="cursor-pointer">
            <a
              href={`/view-customer-details/${form?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeIcon className="h-4 w-4" />
            </a>
          </span>
        </Tooltip>
      </div>
    )
  }, [])

  const onSearchInputChange = value => setSearchValue(value)

  const onSearchExecute = (refetch = false) => {
    refetch ? handleSearch('') : handleSearch(searchValue)
  }

  const onDateRangeSubmit = data => {
    const { date_start, date_end } = formatDateRange(data.dateRange)
    handleDateRange(date_start, date_end)
  }

  const onDateRangeCancel = () => {
    setValue('dateRange', [null, null])
    handleDateRange('', '')
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 p-4">
          <Card
            isHoverable={true}
            radius="sm"
            shadow="sm"
            className="transition-transform transform hover:scale-105 bg-[#F4F4F5] border border-[#F4F4F5] rounded-lg overflow-hidden"
          >
            <CardBody className="p-2 text-center">
              <p className="text-base font-semibold text-gray-800">
                {currentUser?.partner?.offer
                  ? currentUser?.partner?.offer
                  : 'Plan Information Not Available'}
              </p>
            </CardBody>
          </Card>
        </div>

        {isLoading || isUploading ? (
          <div className="flex justify-center py-24">
            <Spinner color="default" size="lg" />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-4">
              <DownloadCSVButtonGroup
                isDataDownloading={isDataDownloading}
                tableData={tableData}
                handleCurrentDataDownload={handleCurrentDataDownload}
                setIsDataDownloading={setIsDataDownloading}
                setIsAttentionOpen={setIsAttentionOpen}
                getClientDataByIds={getClientDataByIds}
                downloadCSVFilename={downloadCSVFilename}
                columnsToExcludeFromCsv={['id', 'partner', 'updated_at']}
                partnerTabAction={partnerTabActionsConst.Customers}
              />
              <Button
                className="py-3 min-w-40 items-center text-xs bg-gray-950 text-white"
                radius="sm"
                variant="solid"
                startContent={<Upload />}
                onClick={handleUploadCsvClick}
              >
                Upload CSV
              </Button>
            </div>
            <div className="flex items-center">
              <div className="basis-8/12">
                <h2 className="text-2xl text-gray-600">
                  Customers Information
                </h2>
              </div>
              <div className="basis-1/6">
                <MultiSelectTableDropdown
                  allOptions={allColumns}
                  defaultSelectedOptions={defaultColumnsToDisplay}
                  onSelectionChange={handleColumnSelectionChange}
                  hideColumns={['id']}
                />
              </div>
              <DateRangeFilterForm
                control={control}
                errors={errors}
                handleSubmit={handleSubmit}
                onDateRangeSubmit={onDateRangeSubmit}
                onDateRangeCancel={onDateRangeCancel}
                watch={watch}
              />
              <div className="basis-1/6">
                <SearchBarComponent
                  value={searchValue}
                  onChange={onSearchInputChange}
                  onSearch={onSearchExecute}
                  inputWrapperClass="border-2 border-gray-500 rounded-lg"
                />
              </div>
            </div>

            <DefaultTableComponent
              columns={tableData.columns}
              data={tableData.data}
              renderActions={renderActions}
              isRowPopUpEnabled={true}
              columnsExclude={['ID']}
            />
          </div>
        )}
      </div>

      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
      <InformationModal
        isOpen={isAttentionOpen}
        onClose={() => setIsAttentionOpen(false)}
        title={requestSubmissionModalWarning.title}
        body={<p>{requestSubmissionModalWarning.body}</p>}
        footerActions={claimsWarningModalActions(handleDownloadInitiate)}
      />

      <InformationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        title={requestSubmissionModalText.title}
        body={<p>{requestSubmissionModalText.body}</p>}
        footerActions={claimsTabModalActions(handleGoToReportDownload)}
      />

      <InformationModal
        isOpen={isUploadCsvModalOpen}
        onClose={() => setisUploadCsvModalOpen(false)}
        title="Upload CSV File"
        body={
          <div className="flex flex-col gap-4">
            <Input
              type="file"
              onChange={handleFileChange}
              accept=".csv"
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100"
            />

            <div className="basis-1/6">
              <FilterDropdown
                options={typeSubmissionOptions}
                value={selectedType}
                onChange={handleTypeChange}
                label="Type"
                Icon={Article}
              />
            </div>
          </div>
        }
        footerActions={uploadReportModalActions(handleUploadCsvSubmit)}
      />
    </>
  )
}
