import axiosInstance from './axiosConfig'
import { AdminEndPoints } from './api-constants'

export const getPartnerFormTemplates = async (page = 1, q = '', f = '') => {
  return await axiosInstance.get(
    AdminEndPoints.getPartnerClaimTemplates(page, q, f),
  )
}

export const addNewFormField = async (access_key, formData) => {
  return await axiosInstance.post(
    AdminEndPoints.addNewFormField(access_key),
    formData,
  )
}

export const deleteFormField = async field_id => {
  return await axiosInstance.delete(AdminEndPoints.deleteFormField(field_id))
}

export const updateFormField = async (field_id, formData) => {
  return await axiosInstance.put(
    AdminEndPoints.updateFormField(field_id),
    formData,
  )
}

export const updateFormColor = async (access_key, formColor) => {
  return await axiosInstance.put(AdminEndPoints.updateFormColor(access_key), {
    color_theme: formColor,
  })
}

export const getParkguardCustomers = async (
  page = 1,
  q = '',
  f = '',
  date_start = '',
  date_end = '',
) => {
  return await axiosInstance.get(
    AdminEndPoints.getParkguardCustomers(page, q, f, date_start, date_end),
  )
}

export const getUserDetails = async id => {
  return await axiosInstance.get(AdminEndPoints.getUserDetails(id))
}

export const getAllPartners = async () => {
  return await axiosInstance.get(AdminEndPoints.getAllParters)
}

export const getParkguardClaimsData = async (
  page = 1,
  q = '',
  f = '',
  date_start = '',
  date_end = '',
) => {
  return await axiosInstance.get(
    AdminEndPoints.getParkguardClaimsData(page, q, f, date_start, date_end),
  )
}

export const getClaimDetails = async id => {
  return await axiosInstance.get(AdminEndPoints.getClaimDetails(id))
}

export const modifyClaimSubmissionData = async (id, formData) => {
  return await axiosInstance.patch(
    AdminEndPoints.modifyClaimSubmission(id),
    formData,
  )
}
