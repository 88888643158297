import { fetchClaimSubmissionDataHelper } from 'components/partner/dashboard/claim-submissions-tab/claim-form-view/claim-view-helper/claim-view-helper'

export const claimSubmissionOptions = [
  {
    value: 'in_review',
    label: 'In Review',
  },
  {
    value: 'declined',
    label: 'Declined',
  },
  {
    value: 'accepted_and_paid',
    label: 'Accepted',
  },
]

export const mapEditClaimDataToApiFormat = formData => {
  const formProcessorClaimNumber = formData.processor_claim_number?.trim()
  const apiData = {
    first_name: formData.firstName || '',
    last_name: formData.lastName || '',
    email: formData.userEmail || '',
    phone_number: formData.phoneNumber || '',
    parking_start_date: formData.dateParked?.[0]
      ? new Date(formData.dateParked[0]).toISOString().split('T')[0]
      : null,
    parking_end_date: formData.dateParked?.[1]
      ? new Date(formData.dateParked[1]).toISOString().split('T')[0]
      : null,
    car_information: `${formData.carInformation?.make || ''} ${formData.carInformation?.model || ''} ${formData.carInformation?.year || ''}`,
    license_plate_info: formData.licensePlateInfo || '',
    license_plate_state: formData.licensePlateState || '',
    type_of_damage: formData.typeOfDamage || '',
    damage_discovery_date: formData.dateDamageDiscovered
      ? new Date(formData.dateDamageDiscovered).toISOString().split('T')[0]
      : '',
    claim_description: formData.claim_description || '',
    mailing_address_info: formData.mailingAddressInfo || '',
    mailing_city: formData.mailingAddressCity || '',
    mailing_state: formData.mailingAddressState || '',
    mailing_zip_code: formData.mailingAddressZipCode || '',
    parking_address_info: formData.parkingAddressInfo || '',
    parking_city: formData.parkingAddressCity || '',
    parking_state: formData.parkingAddressState || '',
    parking_zip_code: formData.parkingAddressZipCode || '',
    is_license_info_available: formData.is_license_info_available || false,
    status: formData.claim_status || 'in_review',
    denial_reason: formData.denial_reason || '',
    claim_amount_paid: formData.claim_amount_paid || '',
  }
  if (formProcessorClaimNumber) {
    apiData.processor_claim_number = formProcessorClaimNumber
  }

  return apiData
}

export const prepareEditClaimDataForModification = data => {
  const mappedData = mapEditClaimDataToApiFormat(data)
  const formData = new FormData()

  Object.keys(mappedData).forEach(key => {
    formData.append(key, mappedData[key])
  })

  return formData
}

export const prepareEditClaimSubmissionData = data => {
  if (!data || typeof data !== 'object') {
    throw new Error('Invalid form data.')
  }

  return prepareEditClaimDataForModification(data)
}

export const transformInitialClaimData = data => ({
  firstName: data.first_name || '',
  lastName: data.last_name || '',
  userEmail: data.email || '',
  dateParked: [data.parking_start_date, data.parking_end_date],
  claim_description: data.claim_description || '',
  mailingAddressInfo: data.mailing_address_info || '',
  mailingAddressCity: data.mailing_city || '',
  mailingAddressState: data.mailing_state || '',
  mailingAddressZipCode: data.mailing_zip_code || '',
  parkingAddressInfo: data.parking_address_info || '',
  parkingAddressCity: data.parking_city || '',
  parkingAddressState: data.parking_state || '',
  parkingAddressZipCode: data.parking_zip_code || '',
  licensePlateState: data.license_plate_state || '',
  licensePlateInfo: data.license_plate_info || '',
  typeOfDamage: data.type_of_damage || '',
  'carInformation.make': data.car_information?.split(' ')[0] || '',
  'carInformation.model': data.car_information?.split(' ')[1] || '',
  'carInformation.year': parseInt(data.car_information?.split(' ')[2]) || '',
  dateDamageDiscovered: data.damage_discovery_date || '',
  phoneNumber: data.phone_number || '',
  claim_status: data.status || '',
  denial_reason: data.denial_reason || '',
  claim_amount_paid: data.claim_amount_paid || '',
  processor_claim_number: data.processor_claim_number || null,
})

export const fetchClaimDataHelper = async (
  role,
  claimId,
  setIsLoading,
  setClaimData,
  setInitialData,
  reset,
) => {
  try {
    const data = await fetchClaimSubmissionDataHelper(
      role,
      claimId,
      setIsLoading,
    )
    const transformedData = transformInitialClaimData(data)
    setClaimData(data)
    setInitialData(transformedData)
    reset(transformedData)
  } catch (error) {
    console.error('Error fetching claim data:', error)
  }
}

export const extractErrorMessages = errorObj => {
  const errorMessages = []

  for (const key in errorObj) {
    if (Array.isArray(errorObj[key])) {
      errorMessages.push(...errorObj[key])
    }
  }

  return errorMessages
}
