export const defaultColumnsToDisplay = [
  'id',
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'pg_identifier',
  'actions',
]
export const allColumns = [
  'id',
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'pg_identifier',
  'booking_date',
  'reservation_id',
  'reservation_start_date',
  'reservation_end_date',
  'protection_plan',
  'protection_plan_price',
  'parking_street_address',
  'parking_city',
  'parking_state',
  'parking_zipcode',
  'created_at',
]

export const dateFields = [
  'reservation_start_date',
  'reservation_end_date',
  'created_at',
]

export const columnsToExcludeFromCsv = ['id', 'updated_at']

export const downloadCSVFilename = 'ParkGuard_Customers_Data.csv'

export const typeSubmissionOptions = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'end_of_month',
    label: 'End of Month',
  },
]

export const uploadReportModalActions = onReportTabPress => [
  { label: 'Close', color: 'danger', variant: 'light' },
  {
    label: 'Upload',
    color: 'default',
    variant: 'light',
    button_background: 'bg-gold-100',
    onPress: onReportTabPress,
  },
]
