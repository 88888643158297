import { isEqual } from 'lodash'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { Spinner, Button } from '@nextui-org/react'
import { UserInfoSection } from './UserInfoSection'
import { yupResolver } from '@hookform/resolvers/yup'
import { useToastMessage } from 'hooks/useToastMessage'
import { useParams, useNavigate } from 'react-router-dom'
import { CustomLoader } from 'components/generics/loader'
import { ClaimProcessSection } from './ClaimProcessSection'
import { ClaimAddressSection } from './ClaimAddressSection'
import VehicleDetailsSection from './VehicleDetailsSection'
import React, { useState, useEffect, useCallback } from 'react'
import { modifyClaimSubmissionData } from 'services/adminService'
import { selectCurrentUser } from 'redux-slices/currentUserSlice'
import { ClaimDescriptionSection } from './ClaimDescriptionSection'
import { AppNavBar } from 'components/navbar/empty-navbar/AppNavBar'
import { editClaimSubmissionSchema } from 'validations/editClaimSubmissionSchema'
import {
  fetchClaimDataHelper,
  prepareEditClaimSubmissionData,
  transformInitialClaimData,
  extractErrorMessages,
} from './edit-claim-constant'

export const EditClaimSubmissionData = () => {
  const navigate = useNavigate()
  const { showToast } = useToastMessage()
  const { claim_id: claimId } = useParams()
  const [saving, setSaving] = useState(false)
  const [claimData, setClaimData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [initialData, setInitialData] = useState(null)
  const { currentUser } = useSelector(selectCurrentUser)

  const isLicenseInfoAvailable = claimData?.is_license_info_available || false

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(editClaimSubmissionSchema),
    defaultValues: {},
    mode: 'onChange',
  })

  const watchedValues = watch()
  const isModified =
    isEqual(initialData, watchedValues) || Object.keys(errors).length > 0

  const fetchClaimData = useCallback(() => {
    fetchClaimDataHelper(
      currentUser.role,
      claimId,
      setIsLoading,
      setClaimData,
      setInitialData,
      reset,
    )
  }, [claimId, currentUser.role, reset])

  useEffect(() => {
    fetchClaimData()
  }, [fetchClaimData])

  const onSubmit = async data => {
    const formData = prepareEditClaimSubmissionData(data)
    setSaving(true)

    try {
      const response = await modifyClaimSubmissionData(claimId, formData)

      if (response.status === 200) {
        showToast('Claim data updated successfully.', 'success')
        const updatedData = transformInitialClaimData(response.data)
        setInitialData(updatedData)
        reset(updatedData)
      } else {
        showToast('Something went wrong. Please try again.', 'error')
      }
    } catch (error) {
      const errorMessages = extractErrorMessages(error?.response?.data || {})
      if (errorMessages.length > 0) {
        errorMessages.forEach(err => showToast(err, 'error'))
      } else {
        showToast('An error occurred. Please try again.', 'error')
      }
    } finally {
      setSaving(false)
    }
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <Spinner color="primary" size="lg" />
      </div>
    )
  }

  if (!claimData) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <p className="text-gray-500">No claim details available.</p>
      </div>
    )
  }

  const handleViewClaim = () => {
    if (claimData?.id) {
      navigate(`/view-claim/${claimData.id}`)
    }
  }

  return (
    <>
      {saving && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <CustomLoader />
        </div>
      )}
      <div className="flex flex-col min-h-screen bg-gradient-to-r from-gray-100 to-gray-50 font-sans antialiased">
        <AppNavBar />
        <div className="flex justify-center items-start py-10">
          <div className="w-full max-w-5xl bg-white shadow-xl rounded-xl p-8 space-y-8">
            <div className="flex items-center justify-between pb-6 border-b border-gray-500 border-dashed">
              <Button
                onClick={handleViewClaim}
                className="bg-gray-900 hover:bg-gray-950 text-white font-medium px-6 py-3 rounded-lg shadow-md transition-all duration-200"
              >
                View Claim
              </Button>
              <h1 className="text-3xl font-bold text-gray-800">
                Modify Claim Submission
              </h1>
              <Button
                type="submit"
                className={`bg-gold-200 hover:bg-gold-100 text-white font-medium px-6 py-3 rounded-lg shadow-md transition-all duration-200 ${
                  isModified ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                disabled={isModified}
                onClick={handleSubmit(onSubmit)}
              >
                Save Changes
              </Button>
            </div>

            <form onSubmit={handleSubmit(onSubmit)} className="space-y-10">
              <ClaimProcessSection
                control={control}
                register={register}
                errors={errors}
                watch={watch}
              />
              <UserInfoSection register={register} errors={errors} />
              <ClaimDescriptionSection register={register} errors={errors} />
              <VehicleDetailsSection
                control={control}
                register={register}
                errors={errors}
                isLicenseInfoAvailable={isLicenseInfoAvailable}
              />
              <ClaimAddressSection register={register} errors={errors} />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
