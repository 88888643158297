import { useForm } from 'react-hook-form'
import { Eraser } from '@phosphor-icons/react'
import { EyeIcon } from '@heroicons/react/outline'
import { usePagination } from 'hooks/usePagination'
import { Spinner, Tooltip } from '@nextui-org/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { usePartnersList } from 'hooks/usePartnersList'
import { dateRangeSchema } from 'validations/dateRangeSchema'
import React, { useMemo, useCallback, useState } from 'react'
import { getParkguardClaimsData } from 'services/adminService'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { SearchBarComponent } from 'components/generics/search-bar/SearchBarComponent'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { MultiSelectTableDropdown } from 'components/generics/multi-select-table-dropdown'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'
import { DateRangeFilterForm } from 'components/partner/dashboard/customers-info-tab/DateRangeFilterForm'
import {
  formatDataDatesByList,
  formatDataForTable,
  formatDateRange,
} from 'helpers'
import {
  defaultColumnsToDisplay,
  allColumns,
  dateFields,
  filterSubmissionOptions,
} from './claims-submission-constants'

export const ParkGuardClaimsSubmissionData = () => {
  const [searchValue, setSearchValue] = useState('')
  const { partnersList } = usePartnersList()
  const [filters, setFilters] = useState({ filter: '', partner: '' })
  const [columnsToDisplay, setColumnsToDisplay] = useState(
    defaultColumnsToDisplay,
  )

  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleSearch,
    handleFilter,
    handleDateRange,
  } = usePagination(getParkguardClaimsData)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(dateRangeSchema),
    defaultValues: { dateRange: [null, null] },
  })

  const tableData = useMemo(() => {
    const formattedData = formatDataDatesByList(data, dateFields)
    return formatDataForTable(formattedData, columnsToDisplay)
  }, [data, columnsToDisplay])

  const renderActions = useCallback(
    form => (
      <div className="flex gap-3 justify-center">
        <Tooltip content="View">
          <span className="cursor-pointer">
            <a
              href={`/view-claim/${form?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <EyeIcon className="h-4 w-4" />
            </a>
          </span>
        </Tooltip>
        <Tooltip content="Edit">
          <span className="cursor-pointer">
            <a
              href={`/edit-claim-form/${form?.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Eraser className="h-4 w-4" />
            </a>
          </span>
        </Tooltip>
      </div>
    ),
    [],
  )

  const onSearchInputChange = value => setSearchValue(value)

  const onSearchExecute = (refetch = false) => {
    refetch ? handleSearch('') : handleSearch(searchValue)
  }

  const handleFilterChange = (filterType, selectedOption) => {
    const selectedValue = selectedOption?.value || ''
    const updatedFilters = {
      ...filters,
      [filterType]: filters[filterType] === selectedValue ? '' : selectedValue,
    }
    setFilters(updatedFilters)
    handleFilter(updatedFilters)
  }

  const handleColumnSelectionChange = selectedColumns => {
    const columnsWithoutActions = selectedColumns.filter(
      col => col !== 'actions',
    )
    setColumnsToDisplay([...columnsWithoutActions, 'actions'])
  }

  const onDateRangeSubmit = data => {
    const { date_start, date_end } = formatDateRange(data.dateRange)
    handleDateRange(date_start, date_end)
  }

  const onDateRangeCancel = () => {
    setValue('dateRange', [null, null])
    handleDateRange('', '')
  }

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4 w-full">
            <div className="basis-8/12">
              <h2 className="text-2xl text-gray-600">Claim Submissions</h2>
            </div>
            <div className="flex justify-end gap-3 items-center px-4">
              <div className="basis-1/8">
                <MultiSelectTableDropdown
                  allOptions={allColumns}
                  defaultSelectedOptions={defaultColumnsToDisplay}
                  onSelectionChange={handleColumnSelectionChange}
                />
              </div>

              <div className="basis-1/8">
                <FilterDropdown
                  label="Filter by Partner"
                  options={partnersList}
                  value={filters.partner}
                  onChange={selectedOption =>
                    handleFilterChange('partner', selectedOption)
                  }
                  buttonClassName="text-nowrap "
                />
              </div>

              <div className="basis-1/8">
                <FilterDropdown
                  label="Filter by Status"
                  options={filterSubmissionOptions}
                  value={filters.filter}
                  onChange={selectedOption =>
                    handleFilterChange('filter', selectedOption)
                  }
                />
              </div>
              <div className="basis-1/6">
                <DateRangeFilterForm
                  control={control}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  onDateRangeSubmit={onDateRangeSubmit}
                  onDateRangeCancel={onDateRangeCancel}
                  watch={watch}
                />
              </div>
              <div className="basis-1/6">
                <SearchBarComponent
                  value={searchValue}
                  onChange={onSearchInputChange}
                  onSearch={onSearchExecute}
                  inputWrapperClass="border-2 border-gray-500 rounded-lg"
                />
              </div>
            </div>
          </div>

          <DefaultTableComponent
            columns={tableData.columns}
            data={tableData.data}
            renderActions={renderActions}
            isRowPopUpEnabled={false}
            columnsExclude={['ID']}
          />
        </div>
      )}

      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
    </>
  )
}
