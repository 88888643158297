import React from 'react'
import { SectionCard } from './SectionCard'
import { formatAndCapitalize } from 'helpers'
import { Tabs, Tab, Button } from '@nextui-org/react'
import { InfoField } from '../../customers-info-tab/customer-detail-view/detail-view-info-field'
import {
  extractAndFormatFilename,
  formatClaimViewAddress,
} from './claim-view-helper/claim-view-helper'

const GridSection = ({ children, cols = 2, gap = 4 }) => (
  <div className={`grid grid-cols-${cols} gap-${gap}`}>{children}</div>
)

const EmptyDataMessage = ({ message }) => (
  <div className="flex justify-center">
    <p className="text-gray-500">{message}</p>
  </div>
)

const RenderAdditionalFields = ({ data }) =>
  Object.entries(data || {}).map(([key, value], index) => (
    <InfoField
      key={index}
      label={key.replace(/_/g, ' ')}
      value={value || 'Not Available'}
    />
  ))

export const TabsSection = ({ claimData, handleOpenSlider }) => (
  <Tabs className="mb-4 flex justify-center">
    <Tab title="Claim Information">
      <SectionCard title="Claim Information">
        <GridSection>
          <InfoField label="First Name" value={claimData.first_name} />
          <InfoField label="Last Name" value={claimData.last_name} />
          <InfoField label="Email" value={claimData.email} />
          <InfoField label="Phone Number" value={claimData.phone_number} />
          <InfoField
            label="Mailing Address"
            value={formatClaimViewAddress(
              claimData.mailing_address_info,
              claimData.mailing_city,
              claimData.mailing_state,
              claimData.mailing_zip_code,
            )}
          />
          <InfoField
            label="Parking Facility Address"
            value={formatClaimViewAddress(
              claimData.parking_address_info,
              claimData.parking_city,
              claimData.parking_state,
              claimData.parking_zip_code,
            )}
          />
          <div className="col-span-2">
            <InfoField
              label="Claim Description"
              value={claimData.claim_description || 'Not Available'}
            />
          </div>
        </GridSection>
      </SectionCard>
    </Tab>

    <Tab title="Damage Information">
      <SectionCard title="Damage Information">
        <GridSection>
          <InfoField label="Car Info" value={claimData.car_information} />
          <InfoField
            label="License Plate"
            value={`${claimData.license_plate_info} (${claimData.license_plate_state || 'N/A'})`}
          />
          <InfoField
            label="Damage Type"
            value={formatAndCapitalize(claimData.type_of_damage)}
          />
          <InfoField
            label="Discovery Date"
            value={claimData.damage_discovery_date}
          />
          <InfoField
            label="Parking Dates"
            value={`${claimData.parking_start_date || 'N/A'} to ${claimData.parking_end_date || 'N/A'}`}
          />
        </GridSection>
      </SectionCard>
    </Tab>

    <Tab title="Receipts">
      <SectionCard title="Receipts">
        {claimData.claim_amount_receipts_list?.length > 0 ? (
          <GridSection cols={2} gap={0}>
            {claimData.claim_amount_receipts_list.map((receipt, index) => (
              <img
                key={index}
                src={receipt}
                alt={`Receipt ${index + 1}`}
                className="rounded-lg shadow-md cursor-pointer max-h-[400px] min-h-[400px] max-w-[400px] min-w-[400px]"
                onClick={() =>
                  handleOpenSlider(claimData.claim_amount_receipts_list)
                }
              />
            ))}
          </GridSection>
        ) : (
          <EmptyDataMessage message="No receipts available." />
        )}
      </SectionCard>
    </Tab>

    <Tab title="Damage Photos">
      <SectionCard title="Damage Photos">
        {claimData.damage_details_images_list?.length > 0 ? (
          <GridSection cols={2}>
            {claimData.damage_details_images_list.map((image, index) => (
              <div key={index} className="flex justify-center flex-grow">
                <img
                  key={index}
                  src={image}
                  alt={`Damage ${index + 1}`}
                  className="rounded-lg shadow-md cursor-pointer max-h-[300px] min-h-[300px] max-w-[300px] min-w-[300px]"
                  onClick={() =>
                    handleOpenSlider(claimData.damage_details_images_list)
                  }
                />
              </div>
            ))}
          </GridSection>
        ) : (
          <EmptyDataMessage message="No damage photos available." />
        )}
      </SectionCard>
    </Tab>

    <Tab title="Additional Fields">
      <SectionCard title="Additional Fields">
        {claimData.additional_fields_responses &&
        Object.keys(claimData.additional_fields_responses).length > 0 ? (
          <GridSection>
            <RenderAdditionalFields
              data={claimData.additional_fields_responses}
            />
          </GridSection>
        ) : (
          <EmptyDataMessage message="No additional fields available." />
        )}
      </SectionCard>
    </Tab>

    <Tab title="Additional Files">
      <SectionCard title="Additional Files">
        <div className="flex justify-center items-center">
          {claimData.additional_files_list?.length > 0 ? (
            <div className="flex flex-wrap gap-4">
              {claimData.additional_files_list.map((file, index) => (
                <Button
                  key={index}
                  onClick={() => window.open(file, '_blank')}
                  className="w-auto bg-gray-950 text-white px-4 py-2 text-center"
                >
                  {extractAndFormatFilename(file)}
                </Button>
              ))}
            </div>
          ) : (
            <EmptyDataMessage message="No additional files available." />
          )}
        </div>
      </SectionCard>
    </Tab>
  </Tabs>
)
