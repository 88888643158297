import React from 'react'
import { FormInput } from 'components/partner/claim-forms/form-generics/FormInputField'

export const UserInfoSection = ({ register, errors }) => {
  return (
    <section>
      <h2 className="text-xl font-semibold text-gray-700 border-b border-gray-200 pb-3">
        User Information
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
        <FormInput
          label="First Name"
          name="firstName"
          register={register}
          errors={errors}
        />
        <FormInput
          label="Last Name"
          name="lastName"
          register={register}
          errors={errors}
        />
        <FormInput
          label="Email"
          name="userEmail"
          type="email"
          register={register}
          errors={errors}
        />
      </div>
    </section>
  )
}
