import { formatPartnerList } from 'helpers'
import { getAllPartners } from 'services/adminService'
import { useState, useEffect, useCallback } from 'react'

export const usePartnersList = () => {
  const [partnersList, setPartnersList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchPartnersList = useCallback(async () => {
    setIsLoading(true)
    try {
      const response = await getAllPartners()
      if (response.status === 200) {
        setPartnersList(formatPartnerList(response.data))
      } else {
        throw new Error('Failed to fetch partners list')
      }
    } catch (err) {
      setError(err.message || 'Something went wrong')
      console.error('Failed to fetch partners list:', err)
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchPartnersList()
  }, [fetchPartnersList])

  return { partnersList, isLoading, error }
}
