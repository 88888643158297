import React from 'react'
import { getFormFieldError } from 'helpers'
import { Controller } from 'react-hook-form'
import { Checkbox } from '@nextui-org/react'

export const FormCheckbox = ({
  control = () => {},
  name = '',
  label = '',
  className = '',
  disabled = false,
  errors = () => {},
  children,
}) => {
  const fieldError = getFormFieldError(errors, name)

  return (
    <div className={`flex flex-col ${className}`}>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <div className="flex items-center gap-2">
            <Checkbox
              isSelected={field.value || false}
              onChange={e => {
                field.onChange(e.target.checked)
              }}
              className="p-0 m-0"
              isDisabled={disabled}
            >
              {label && (
                <span className="text-xs text-gray-600 leading-[10px]">
                  {label}
                </span>
              )}
            </Checkbox>
            {children && <div className="flex-1">{children}</div>}
          </div>
        )}
      />
      {fieldError && (
        <span className="text-red-500 text-sm mt-1">{fieldError.message}</span>
      )}
    </div>
  )
}
