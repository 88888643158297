import React from 'react'
import Slider from 'react-slick'
import { X } from '@phosphor-icons/react'
import { Button } from '@nextui-org/react'

export const ImageSlider = ({ allImages = [], onClose }) => (
  <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 p-6">
    <Button
      className="absolute top-4 right-4 z-50 bg-transparent border-none"
      onClick={onClose}
    >
      <X size={28} className="text-white" />
    </Button>
    {allImages && allImages.length > 0 ? (
      <div className="relative w-full max-w-4xl">
        <Slider
          dots={true}
          fade={true}
          infinite={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
        >
          {allImages.map((imageUrl, index) => (
            <div key={index} className="flex justify-center items-center">
              <img
                src={imageUrl}
                alt={`Image ${index + 1}`}
                className="object-contain rounded-lg w-full h-[768px]"
              />
            </div>
          ))}
        </Slider>
      </div>
    ) : (
      <div className="text-white text-center">
        <p>No images provided.</p>
      </div>
    )}
  </div>
)
