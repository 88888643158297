export const damageTypes = [
  { label: 'Theft', value: 'theft' },
  { label: 'Door Ding', value: 'door_ding' },
  { label: 'Broken Window', value: 'broken_window' },
  { label: 'Other Damage', value: 'other_damage' },
  { label: 'Multiple Damage Types', value: 'multiple_damage' },
]

export const claimFormCheckboxOptions = {
  legalFormClause:
    'By checking this box, I certify that all information provided is truthful and accurate. I understand that this is a legal form and that any fraudulent activity or information may result in consequences and the denial of my claim.',
  termsAndConditions: `
    By checking this box, I agree to Park Guard’s 
    <a href="https://www.parkguard.com/terms-of-use" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">
      Terms of Use
    </a> and 
    <a href="https://www.parkguard.com/privacy-policy" target="_blank" rel="noopener noreferrer" class="text-blue-500 underline">
      Privacy Policy
    </a>, as well as any and all partner company’s terms of use and privacy policy where the parking was originally reserved.
  `,
  adminTermsAndConditions:
    'By checking this box, I agree to Park Guard’s Terms of Use and Privacy Policy, as well as any and all partner company’s terms of use and privacy policy where the parking was originally reserved.',
}

export const claimFormUserInstructions =
  'Please submit the claim form as soon as damage is discovered even if you do not have all the necessary information. Our team will work with you to gather more details after the form is submitted'

export const uploadFilesConfig = [
  {
    name: 'damagePhotos',
    label: 'Upload Photos of Damage',
    description: 'Max 5 images and 15MB total allowed',
    maxAllowedFiles: 5,
    maxTotalSize: 15 * 1024 * 1024,
    accept: 'image/*,application/pdf',
    allowedFileTypes: ['image/jpeg', 'image/png'],
  },
  {
    name: 'receiptFiles',
    label: 'Upload Receipt',
    description: 'Max 2 images and 8MB total allowed',
    maxAllowedFiles: 2,
    accept: 'image/*,application/pdf',
    allowedFileTypes: ['image/jpeg', 'image/png'],
  },
  {
    name: 'additionalFiles',
    label: 'Upload Additional Documents',
    description: 'Max 5 files and 8MB total allowed',
    maxAllowedFiles: 5,
    accept: 'image/*,application/pdf',
    allowedFileTypes: ['image/jpeg', 'image/png', 'application/pdf'],
  },
]

export const claimFormLicenseInfoClause = {
  licenseInfo: 'I don’t know my license plate info',
}
