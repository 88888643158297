import React from 'react'
import { Users, ListPlus, Files } from '@phosphor-icons/react'

export const menuItems = [
  {
    to: '/admin/dashboard',
    icon: <ListPlus className="w-5 h-5 text-black" />,
    label: 'Form Templates',
  },
  {
    to: '/admin/dashboard/parkguard-customers-data',
    icon: <Users className="w-5 h-5 text-black" />,
    label: 'Customers',
  },
  {
    to: '/admin/dashboard/parkguard-claims-submission-data',
    icon: <Files className="w-5 h-5 text-black" />,
    label: 'Claim Submissions',
  },
]
