import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useToastMessage } from 'hooks/useToastMessage'
import {
  setCurrentUser,
  selectCurrentUser,
  clearCurrentUser,
} from 'redux-slices/currentUserSlice'
import {
  authenticateUser,
  generateQRCode,
  verifyOTP,
} from 'services/userService'

const roleToPath = {
  parkguard_employee: '/admin/dashboard',
  partner_employee: '/partner/dashboard',
  default: '/',
}

const storeToken = token => {
  if (token) {
    localStorage.setItem('accessToken', token)
  }
}

const removeToken = () => {
  localStorage.removeItem('accessToken')
}

export const useAuth = () => {
  const { showToast } = useToastMessage()
  const { currentUser } = useSelector(selectCurrentUser)
  const [isLoading, setIsLoading] = useState(false)
  const [requires2FASetup, setRequires2FASetup] = useState(false)
  const [isRoleCheckLoading, setIsRoleCheckLoading] = useState(true)
  const [requires2FAVerification, setRequires2FAVerification] = useState(false)
  const [authToken, setAuthToken] = useState('')
  const [qrCodeUrl, setQrCodeUrl] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = { username: '', password: '' }

  const handleError = (error, defaultMessage) => {
    const errorMessage =
      error?.response?.data?.errors ||
      error?.response?.data?.error ||
      defaultMessage
    showToast(`${errorMessage} Please try again.`, 'error')
    console.error(defaultMessage, error)
  }

  const handleSubmit = async values => {
    try {
      setIsLoading(true)
      removeToken()

      const response = await authenticateUser(values.username, values.password)
      const { token, requires_2fa_setup, requires_2fa_verification } =
        response.data

      if (requires_2fa_setup) {
        setAuthToken(token)
        setRequires2FASetup(true)
        const qrResponse = await generateQRCode(token)
        setQrCodeUrl(qrResponse?.data?.qr_code_url)
      } else if (requires_2fa_verification) {
        setAuthToken(token)
        setRequires2FAVerification(true)
      }
    } catch (error) {
      handleError(error, 'Login failed.')
    } finally {
      setIsLoading(false)
    }
  }

  const handleOTPVerification = async otpCode => {
    try {
      setIsLoading(true)
      const response = await verifyOTP(otpCode, authToken)
      const { user, token } = response.data

      storeToken(token)
      dispatch(setCurrentUser(user))

      const role = user.role?.toLowerCase()
      const path = roleToPath[role] || roleToPath.default
      navigate(path)
    } catch (error) {
      handleError(error, 'OTP verification failed')
    } finally {
      setIsLoading(false)
    }
  }

  const handleInvalidAccess = () => {
    dispatch(clearCurrentUser())
    navigate('/')
  }

  const navigateBasedOnRole = () => {
    const token = localStorage.getItem('accessToken')

    if (!token || !currentUser || !currentUser.role) {
      handleInvalidAccess()
      setIsRoleCheckLoading(false)
      return
    }

    const role = currentUser.role.toLowerCase()
    const path = roleToPath[role]

    if (path) {
      navigate(path)
    } else {
      handleInvalidAccess()
    }

    setIsRoleCheckLoading(false)
  }

  return {
    initialValues,
    handleSubmit,
    handleOTPVerification,
    isLoading,
    requires2FASetup,
    requires2FAVerification,
    qrCodeUrl,
    navigateBasedOnRole,
    isRoleCheckLoading,
  }
}
