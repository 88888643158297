import React from 'react'
import { useLocation } from 'react-router-dom'
import { ParkGuardCustomersData } from './customers-data'
import { EditClaimTemplatePage } from 'pages/admin/edit-template'
import { PartnerClaimFormTemplates } from './partner-form-templates'
import { ParkGuardClaimsSubmissionData } from './claims-submission-data'

export const AdminDashboard = () => {
  const location = useLocation()
  const pathname = location.pathname
  return (
    <>
      <div>
        <div>
          <div className="flex flex-col gap-4">
            <div className="flex justify-center">
              <h1 className="text-3xl text-gray-600 font-bold">
                Welcome to Park Guard Coverage Hub
              </h1>
            </div>

            {pathname === '/admin/dashboard' && <PartnerClaimFormTemplates />}
            {pathname === '/admin/dashboard/parkguard-customers-data' && (
              <ParkGuardCustomersData />
            )}
            {pathname ===
              '/admin/dashboard/parkguard-claims-submission-data' && (
              <ParkGuardClaimsSubmissionData />
            )}
            {pathname.includes('edit-claim-template') && (
              <EditClaimTemplatePage />
            )}
          </div>
        </div>
      </div>
    </>
  )
}
