import React from 'react'
import { FormInput } from 'components/partner/claim-forms/form-generics/FormInputField'

export const ClaimAddressSection = ({ register, errors }) => {
  return (
    <>
      <section>
        <h2 className="text-xl font-semibold text-gray-700 border-b border-gray-200 pb-3 mb-4">
          Parking Address
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormInput
            label="Address"
            name="parkingAddressInfo"
            register={register}
            errors={errors}
          />
          <FormInput
            label="City"
            name="parkingAddressCity"
            register={register}
            errors={errors}
          />
          <FormInput
            label="State"
            name="parkingAddressState"
            register={register}
            errors={errors}
          />
          <FormInput
            label="Zip Code"
            name="parkingAddressZipCode"
            register={register}
            errors={errors}
          />
        </div>
      </section>

      <section>
        <h2 className="text-xl font-semibold text-gray-700 border-b border-gray-200 pb-3 mb-4">
          Mailing Address
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <FormInput
            label="Address"
            name="mailingAddressInfo"
            register={register}
            errors={errors}
          />
          <FormInput
            label="City"
            name="mailingAddressCity"
            register={register}
            errors={errors}
          />
          <FormInput
            label="State"
            name="mailingAddressState"
            register={register}
            errors={errors}
          />
          <FormInput
            label="Zip Code"
            name="mailingAddressZipCode"
            register={register}
            errors={errors}
          />
        </div>
      </section>
    </>
  )
}
