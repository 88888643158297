import { formatDateParams } from 'helpers'

export const AuthEndPoints = {
  login: '/api/auth/login/',
  createUser: '/api/auth/register/',
  getUserDetails: '/api/auth/user/',
  generateQRCode: '/api/auth/2fa/generate_qr_code/',
  verifyOTP: '/api/auth/2fa/verify_otp/',
}

export const PartnerEndPoints = {
  submitPartnerClaimForm: '/api/partner/form-submission/',
  updatePartnerOfferDetails: '/api/partner/update-offer/',
  getClaimDataByIds: '/api/partner/claims-by-ids/',
  getClientsById: '/api/partner/clients-by-ids/',
  createAllClaimsDownload: '/api/partner/generate-claim-report/',
  createAllClientDownload: '/api/partner/generate-customer-report/',
  uploadReport: '/api/partner/upload-report/',
  generateBatchPresignedUrls: '/api/partner/generate-presigned-urls/',

  getAllClaimSubmissions: (page, q, filter, date_start, date_end) => {
    const url = `/api/partner/claims/?page=${page}`
    const params = [`q=${q}`, `filter=${filter}`]
    params.push(...formatDateParams(date_start, date_end))

    return `${url}&${params.join('&')}`
  },

  getAllPartnerCustomers: (page, q, f, date_start, date_end) => {
    const url = `/api/partner/clients/?page=${page}`
    const params = [`q=${q}`]

    if (f) params.push(`filter=${f}`)
    params.push(...formatDateParams(date_start, date_end))

    return `${url}&${params.join('&')}`
  },

  getPartnerFormTemplates: (page, q) => {
    return `api/partner/forms/?page=${page}&q=${q}`
  },

  verifyClaimTemplate: url_suffix => {
    return `api/partner/verify-template/${url_suffix}`
  },

  getClaimFormOfClient: claim_form_id => {
    return `/api/partner/claims/${claim_form_id}/`
  },

  getAllDownloadReports: (page, q, f, date_start, date_end) => {
    const url = `/api/partner/reports/?page=${page}`
    const params = []

    if (f) params.push(`type=${f}`)
    params.push(...formatDateParams(date_start, date_end))

    return `${url}&${params.join('&')}`
  },

  getAllMonthlyReports: (page, q, f, date_start, date_end) => {
    const url = `/api/partner/uploaded-reports/?page=${page}`
    const params = []

    if (f) params.push(`type=${f}`)
    params.push(...formatDateParams(date_start, date_end))

    return `${url}&${params.join('&')}`
  },
}

export const CustomerEndPoints = {
  getCustomerDetails: id => `/api/partner/clients/${id}`,
}

export const AdminEndPoints = {
  getAllParters: `/api/admin/partners`,
  addNewFormField: access_key =>
    `/api/admin/templates/${access_key}/fields/add/`,
  deleteFormField: field_id => `/api/admin/fields/${field_id}/delete/`,
  getPartnerClaimTemplates: (page, q, f) => {
    const url = `/api/admin/form-templates?page=${page}`
    const params = []

    if (q) params.push(`q=${q}`)
    if (f) params.push(`partner=${f}`)

    return `${url}&${params.join('&')}`
  },
  updateFormField: field_id => `/api/admin/fields/${field_id}/update/`,
  updateFormColor: access_key => `/api/admin/templates/${access_key}/update/`,
  getUserDetails: id => `/api/admin/customer/${id}`,
  getClaimDetails: id => `/api/admin/claim-submission/${id}`,
  getParkguardCustomers: (page, q, f, date_start, date_end) =>
    `/api/admin/customers?page=${page}&q=${q}&date_start=${date_start}&date_end=${date_end}&partner=${f}`,
  modifyClaimSubmission: id => `/api/admin/claim-submission/${id}`,
  getParkguardClaimsData: (page, q, f, date_start, date_end) => {
    const url = `/api/admin/claim-submissions?page=${page}&q=${q}`
    const params = []

    if (f) {
      if (f.filter) params.push(`filter=${f.filter}`)
      if (f.partner) params.push(`partner=${f.partner}`)
    }

    if (date_start && date_end) {
      params.push(`date_start=${date_start}`, `date_end=${date_end}`)
    }

    return `${url}&${params.join('&')}`
  },
}
