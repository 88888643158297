import React from 'react'
import { useFormContext } from 'react-hook-form'
import { FileUploadField } from 'components/generics/file-upload-button'

export const FileUploadSection = ({ uploadConfigs }) => {
  const { control } = useFormContext()

  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 py-4">
      {uploadConfigs &&
        uploadConfigs.map(
          (
            {
              name,
              label,
              description,
              maxAllowedFiles,
              maxTotalSize,
              accept,
              allowedFileTypes,
            },
            index,
          ) => (
            <FileUploadField
              key={index}
              control={control}
              name={name}
              label={label}
              description={description}
              buttonSize="sm"
              accept={accept}
              allowedFileTypes={allowedFileTypes}
              multiple
              maxAllowedFiles={maxAllowedFiles}
              maxTotalSize={maxTotalSize}
              className="min-w-[4rem]"
              buttonProps={{
                children: 'Select Files',
                className: 'w-full max-w-80 bg-gold-100',
              }}
              mainButtonClasses=" "
            />
          ),
        )}
    </div>
  )
}
