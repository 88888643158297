import { useSelector } from 'react-redux'
import { Spinner } from '@nextui-org/react'
import { ImageSlider } from './ImageSlider'
import { useParams } from 'react-router-dom'
import { TabsSection } from './ClaimViewTabsSection'
import { HeaderSection } from './CalimViewHeaderSection'
import React, { useState, useEffect, useCallback } from 'react'
import { selectCurrentUser } from 'redux-slices/currentUserSlice'
import { AppNavBar } from 'components/navbar/empty-navbar/AppNavBar'
import { fetchClaimSubmissionDataHelper } from './claim-view-helper/claim-view-helper'
import { generateCustomerClaimSubmissionPDF } from './claim-view-helper/create-claim-view-pdf'

export const ClaimFormSingleView = () => {
  const { claim_id: claimId } = useParams()
  const [claimData, setClaimData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [showSlider, setShowSlider] = useState(false)
  const [sliderImages, setSliderImages] = useState([])
  const { currentUser } = useSelector(selectCurrentUser)

  const fetchClaimData = useCallback(async () => {
    const data = await fetchClaimSubmissionDataHelper(
      currentUser.role,
      claimId,
      setIsLoading,
    )
    setClaimData(data)
  }, [claimId])

  useEffect(() => {
    fetchClaimData()
  }, [fetchClaimData])

  const handleOpenSlider = images => {
    setSliderImages(images)
    setShowSlider(true)
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <Spinner color="primary" size="lg" />
      </div>
    )
  }

  if (!claimData) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-50">
        <p className="text-gray-500">No claim details available.</p>
      </div>
    )
  }

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 font-sans antialiased">
      <AppNavBar />
      <div className="flex justify-center items-start p-8">
        <div className="w-full max-w-5xl bg-white rounded-lg shadow-lg p-6 min-h-dvh justify-center">
          <HeaderSection
            userRole={currentUser.role}
            status={claimData.status}
            created_at={claimData.created_at}
            claim_amount_paid={claimData.claim_amount_paid}
            handleDownloadPDF={() =>
              generateCustomerClaimSubmissionPDF(claimData)
            }
            claimDataId={claimData.id}
          />
          <TabsSection
            claimData={claimData}
            handleOpenSlider={handleOpenSlider}
          />
          {showSlider && (
            <ImageSlider
              allImages={sliderImages}
              onClose={() => setShowSlider(false)}
            />
          )}
        </div>
      </div>
    </div>
  )
}
