import { Pencil } from '@phosphor-icons/react'
import { usePagination } from 'hooks/usePagination'
import { Spinner, Tooltip } from '@nextui-org/react'
import { usePartnersList } from 'hooks/usePartnersList'
import React, { useCallback, useMemo, useState } from 'react'
import { getPartnerFormTemplates } from 'services/adminService'
import { formatDataForTable, formatDataDatesByList } from 'helpers'
import { FilterDropdown } from 'components/generics/filter-dropdown/FilterDropDown'
import { PaginationComponent } from 'components/generics/pagination/PaginationComponent'
import { MultiSelectTableDropdown } from 'components/generics/multi-select-table-dropdown'
import { DefaultTableComponent } from 'components/generics/default-table/DefaultTableComponent'
import {
  defaultColumnsToDisplay,
  allColumns,
  dateFields,
} from './form-templates-constant'

export const PartnerClaimFormTemplates = () => {
  const { partnersList } = usePartnersList()
  const [selectedFilter, setSelectedFilter] = useState(null)
  const [columnsToDisplay, setColumnsToDisplay] = useState(
    defaultColumnsToDisplay,
  )
  const {
    data,
    totalPages,
    currentPage,
    isLoading,
    handlePageChange,
    handleFilter,
  } = usePagination(getPartnerFormTemplates)

  const tableData = useMemo(() => {
    const formattedData = formatDataDatesByList(data, dateFields)
    return formatDataForTable(formattedData, columnsToDisplay)
  }, [data, columnsToDisplay])

  const handleColumnSelectionChange = selectedColumns => {
    const columnsWithoutActions = selectedColumns.filter(
      col => col !== 'actions',
    )
    setColumnsToDisplay([...columnsWithoutActions, 'actions'])
  }

  const renderActions = useCallback(form => {
    return (
      <div className="flex gap-3 justify-center">
        <Tooltip content="Customised">
          <span className="cursor-pointer">
            <a
              href={`/admin/dashboard/edit-claim-template/${form?.url_suffix}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Pencil size={15} />
            </a>
          </span>
        </Tooltip>
      </div>
    )
  }, [])

  const handleFilterChange = selectedOption => {
    setSelectedFilter(
      selectedOption?.value === selectedFilter ? '' : selectedOption?.value,
    )
    handleFilter(selectedOption?.value || '')
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        {isLoading ? (
          <div className="flex justify-center py-24">
            <Spinner color="default" size="lg" />
          </div>
        ) : (
          <div className="flex flex-col gap-4">
            <div className="flex items-center">
              <div className="basis-8/12">
                <h2 className="text-2xl text-gray-600">Partner Templates</h2>
              </div>
              <div className="basis-1/6 px-4">
                <FilterDropdown
                  label="Filter by Partner"
                  options={partnersList}
                  value={selectedFilter}
                  onChange={handleFilterChange}
                  buttonClassName="text-nowrap "
                />
              </div>
              <div className="basis-1/6">
                <MultiSelectTableDropdown
                  allOptions={allColumns}
                  defaultSelectedOptions={defaultColumnsToDisplay}
                  onSelectionChange={handleColumnSelectionChange}
                />
              </div>
            </div>

            <DefaultTableComponent
              columns={tableData.columns}
              data={tableData.data}
              renderActions={renderActions}
              columnsExclude={['PARTNER ID']}
            />
          </div>
        )}
      </div>

      <PaginationComponent
        total={totalPages}
        page={currentPage}
        onChange={handlePageChange}
        className="p-4 mt-3 flex flex-col items-end gap-x-2"
      />
    </>
  )
}
