import * as Yup from 'yup'

export const editClaimSubmissionSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  userEmail: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  dateParked: Yup.array()
    .of(Yup.string().required('Both start and end dates are required'))
    .required('Date range is required')
    .min(2, 'Please select a start and end date'),
  carInformation: Yup.object().shape({
    make: Yup.string().required('Car make is required'),
    model: Yup.string().required('Car model is required'),
    year: Yup.number()
      .typeError('Year must be a number')
      .positive('Year must be positive')
      .integer('Year must be an integer')
      .min(1951, 'Year must be greater than 1950')
      .max(new Date().getFullYear(), 'Year cannot be in the future')
      .test(
        'len',
        'Year must be at most 4 digits',
        val => val && val.toString().length <= 4,
      ),
  }),
  is_license_info_available: Yup.boolean().default(false),
  licensePlateInfo: Yup.string().when('is_license_info_available', {
    is: true,
    then: () => Yup.string().required('License Plate Info is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  licensePlateState: Yup.string().when('is_license_info_available', {
    is: true,
    then: () => Yup.string().required('License Plate State is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  typeOfDamage: Yup.string().required('Type of Damage is required'),
  phoneNumber: Yup.string().required('Phone Number is required'),
  dateDamageDiscovered: Yup.date()
    .required('Date Damage Discovered is required')
    .max(new Date(), 'Date cannot be in the future'),
  claim_description: Yup.string()
    .required('Description of Damage or Loss is required')
    .max(1000, 'Description must not exceed 1000 characters'),
  mailingAddressInfo: Yup.string().required('Mailing Address Info is required'),
  mailingAddressCity: Yup.string().required('Mailing Address City is required'),
  mailingAddressState: Yup.string().required(
    'Mailing Address State is required',
  ),
  mailingAddressZipCode: Yup.string().required(
    'Mailing Address Zip Code is required',
  ),
  parkingAddressInfo: Yup.string().required('Parking Address Info is required'),
  parkingAddressCity: Yup.string().required('Parking Address City is required'),
  parkingAddressState: Yup.string().required(
    'Parking Address State is required',
  ),
  parkingAddressZipCode: Yup.string().required(
    'Parking Address Zip Code is required',
  ),
  claim_status: Yup.string().required('Claim status is required'),
  denial_reason: Yup.string().when('claim_status', {
    is: 'declined',
    then: () => Yup.string().required('Denial reason is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  claim_amount_paid: Yup.string().when('claim_status', {
    is: 'accepted_and_paid',
    then: () => Yup.string().required('Claim Amount Paid is required'),
    otherwise: () => Yup.string().notRequired(),
  }),
  processor_claim_number: Yup.string().notRequired(),
})
