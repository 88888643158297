import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { formatAndCapitalize } from 'helpers'
import { InfoField } from './detail-view-info-field'
import { Spinner, Tab, Tabs } from '@nextui-org/react'
import { getUserDetails } from 'services/adminService'
import { useToastMessage } from 'hooks/useToastMessage'
import { SectionCard } from './detail-view-section-card'
import { getCustomerDetails } from 'services/customerService'
import React, { useState, useEffect, useCallback } from 'react'
import { selectCurrentUser } from 'redux-slices/currentUserSlice'
import { AppNavBar } from 'components/navbar/empty-navbar/AppNavBar'
import { USER_ROLES } from 'helpers/app-constants'

export const CustomerDetailsView = () => {
  const { showToast } = useToastMessage()
  const { customer_id: customerId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [customerData, setCustomerData] = useState(null)
  const { currentUser } = useSelector(selectCurrentUser)

  const getCustomerAllDetail = useCallback(async () => {
    try {
      const response =
        currentUser.role === USER_ROLES.PARTNER_EMPLOYEE
          ? await getCustomerDetails(customerId)
          : await getUserDetails(customerId)

      if (response.status === 200) {
        setCustomerData(response.data)
      }
    } catch (error) {
      showToast('Failed to get customer details. Please try again.', 'error')
      console.error('Failed to get customer details:', error)
    } finally {
      setIsLoading(false)
    }
  }, [customerId])

  useEffect(() => {
    getCustomerAllDetail()
  }, [getCustomerAllDetail])

  if (isLoading) {
    return (
      <div className="flex justify-center py-24">
        <Spinner color="default" size="lg" />
      </div>
    )
  }

  if (!customerData) {
    return (
      <div className="flex justify-center py-24">
        <p className="text-gray-500">No customer details available.</p>
      </div>
    )
  }

  const parkingAddress = [
    customerData.parking_street_address,
    customerData.parking_city,
    customerData.parking_state,
    customerData.parking_zipcode,
  ]
    .filter(field => field)
    .join(', ')

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 font-sans antialiased">
      <AppNavBar />
      <div className="flex justify-center items-start p-8">
        <div className="w-full max-w-5xl bg-white rounded-lg shadow-lg p-6">
          <div className="text-center mb-6">
            <h1 className="text-3xl font-bold text-gray-800">
              Customer Details
            </h1>
            <p className="text-gray-500">Park Guard Coverage Hub</p>
          </div>

          <Tabs
            aria-label="Customer Details"
            className="mb-4 flex justify-center"
          >
            <Tab title="Personal Info">
              <SectionCard title="Personal Information">
                {[
                  'first_name',
                  'last_name',
                  'email',
                  'phone_number',
                  'pg_identifier',
                ].map(field => (
                  <InfoField
                    key={field}
                    label={formatAndCapitalize(field)}
                    value={customerData[field]}
                  />
                ))}
              </SectionCard>
            </Tab>

            <Tab title="Parking & Reservation">
              <SectionCard title="Parking & Reservation Details">
                <InfoField
                  label="Parking Address"
                  value={parkingAddress || 'N/A'}
                />
                {[
                  'reservation_start_date',
                  'reservation_start_time',
                  'reservation_end_date',
                  'reservation_end_time',
                  'reservation_id',
                ].map(field => (
                  <InfoField
                    key={field}
                    label={formatAndCapitalize(field)}
                    value={customerData[field]}
                  />
                ))}
              </SectionCard>
            </Tab>

            <Tab title="Booking & Car Info">
              <SectionCard title="Booking & Car Information">
                {[
                  'booking_date',
                  'car_make',
                  'car_model',
                  'car_year',
                  'booking_site',
                  'protection_plan',
                  'protection_plan_price',
                ].map(field => (
                  <InfoField
                    key={field}
                    label={formatAndCapitalize(field)}
                    value={customerData[field]}
                  />
                ))}
              </SectionCard>
            </Tab>

            <Tab title="Additional Information">
              {customerData.additional_info &&
              Object.keys(customerData.additional_info).length > 0 ? (
                <SectionCard title="Additional Details">
                  {Object.entries(customerData.additional_info).map(
                    ([key, value], index) => (
                      <InfoField
                        key={index}
                        label={formatAndCapitalize(key)}
                        value={value || 'N/A'}
                      />
                    ),
                  )}
                </SectionCard>
              ) : (
                <div className="text-center py-6">
                  <p className="text-gray-500 text-sm font-medium">
                    No additional information available.
                  </p>
                </div>
              )}
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
