import { FileList } from './FileList'
import { Button } from '@nextui-org/react'
import { useController } from 'react-hook-form'
import { UploadIcon } from '@heroicons/react/outline'
import { useToastMessage } from 'hooks/useToastMessage'
import React, { useRef, useCallback, useMemo } from 'react'
import { getFileUploadErrorMessages } from 'helpers/error-helpers'

export const FileUploadField = ({
  control,
  name,
  label,
  description = '',
  accept,
  buttonSize = 'lg',
  multiple = false,
  maxAllowedFiles = 10,
  maxTotalSize = 8 * 1024 * 1024,
  allowedFileTypes = [],
  buttonProps = {},
  mainButtonClasses = '',
}) => {
  const {
    field: { onChange, value },
    fieldState: { error },
  } = useController({ name, control })

  const inputRef = useRef(null)
  const files = useMemo(() => value || [], [value])
  const errorMessages = getFileUploadErrorMessages(error)
  const { showToast } = useToastMessage()

  const handleFileUpload = useCallback(
    e => {
      const filesArray = Array.from(e.target.files)

      const invalidFiles = filesArray.filter(
        file => !allowedFileTypes.includes(file.type),
      )

      if (invalidFiles.length > 0) {
        showToast('File type not allowed', 'error')
        e.target.value = ''
        return
      }

      const totalSize =
        files.reduce((acc, file) => acc + (file.size || 0), 0) +
        filesArray.reduce((acc, file) => acc + file.size, 0)

      if (totalSize > maxTotalSize) {
        showToast(
          `The total file size cannot exceed ${maxTotalSize / (1024 * 1024)} MB`,
          'error',
        )
        e.target.value = ''
        return
      }

      const newFiles = multiple
        ? [...files, ...filesArray].slice(0, maxAllowedFiles)
        : filesArray.length
          ? [filesArray[0]]
          : []

      onChange(newFiles)
      e.target.value = ''
    },
    [
      onChange,
      multiple,
      files,
      maxAllowedFiles,
      maxTotalSize,
      allowedFileTypes,
    ],
  )

  const removeFile = useCallback(
    index => {
      if (multiple) {
        const newFiles = [...files]
        newFiles.splice(index, 1)
        onChange(newFiles)
      } else {
        onChange(null)
      }
    },
    [files, onChange, multiple],
  )

  return (
    <div className={mainButtonClasses}>
      <div className="flex flex-col gap-0 mb-2">
        <label className="block font-medium text-gray-700">{label}</label>
        <span className="text-gray-400 text-xs">{description}</span>
      </div>
      <Button
        size={buttonSize}
        onPress={() => inputRef.current?.click()}
        startContent={<UploadIcon className="h-5 w-5 text-gray-500" />}
        {...buttonProps}
      >
        {buttonProps.children || 'Upload'}
      </Button>
      <input
        type="file"
        accept={accept}
        multiple={multiple}
        ref={inputRef}
        onChange={handleFileUpload}
        className="hidden"
      />
      {files.length > 0 && <FileList files={files} removeFile={removeFile} />}
      {files.length >= maxAllowedFiles && (
        <span className="text-gray-400 text-sm mt-1">
          You can upload up to {maxAllowedFiles} files.
        </span>
      )}
      {errorMessages?.length > 0 && (
        <>
          {errorMessages.map((msg, index) => (
            <div key={index} className="text-red-500 text-sm mt-1">
              {msg}
            </div>
          ))}
        </>
      )}
    </div>
  )
}
