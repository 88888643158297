import React from 'react'
import { Button } from '@nextui-org/react'
import { useNavigate } from 'react-router-dom'
import { USER_ROLES } from 'helpers/app-constants'
import { formatAndCapitalize, formatDateToUTC } from 'helpers'

export const HeaderSection = ({
  userRole,
  status,
  created_at,
  claim_amount_paid,
  handleDownloadPDF,
  claimDataId,
}) => {
  const navigate = useNavigate()

  const handleModifyClaim = () => {
    if (claimDataId) {
      navigate(`/edit-claim-form/${claimDataId}`)
    }
  }

  return (
    <>
      <div className="p-2 mb-6 flex items-center w-full">
        {userRole === USER_ROLES.PARKGUARD_EMPLOYEE && (
          <div className="flex-none mr-4">
            <Button
              color="primary"
              className="bg-gray-950 text-white"
              onClick={handleModifyClaim}
            >
              Modify Claim
            </Button>
          </div>
        )}

        <div className="flex-1 text-center">
          <h1 className="text-3xl font-bold text-gray-800">Claim Details</h1>
          <p className="text-gray-500">Park Guard Claim Overview</p>
        </div>
        <div className="flex-none ml-4">
          <Button
            color="primary"
            className="bg-gray-950 text-white"
            onClick={handleDownloadPDF}
          >
            Download Claim
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="p-6 bg-blue-50 rounded-lg shadow-md text-center">
          <h3 className="text-sm font-semibold text-gray-500">Status</h3>
          <p className="text-lg font-bold">
            {formatAndCapitalize(status) || 'N/A'}
          </p>
        </div>
        <div className="p-6 bg-green-50 rounded-lg shadow-md text-center">
          <h3 className="text-sm font-semibold text-gray-500">Submitted On</h3>
          <p className="text-lg font-bold">
            {formatDateToUTC(created_at) || 'N/A'}
          </p>
        </div>
        <div className="p-6 bg-purple-50 rounded-lg shadow-md text-center">
          <h3 className="text-sm font-semibold text-gray-500">Amount Paid</h3>
          <p className="text-lg font-bold">{claim_amount_paid || 'Not Paid'}</p>
        </div>
      </div>
    </>
  )
}
