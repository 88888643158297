export const defaultColumnsToDisplay = [
  'id',
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'pg_identifier',
  'actions',
]
export const allColumns = [
  'id',
  'first_name',
  'last_name',
  'email',
  'phone_number',
  'pg_identifier',
  'booking_date',
  'reservation_id',
  'reservation_start_date',
  'reservation_end_date',
  'protection_plan',
  'protection_plan_price',
  'parking_street_address',
  'parking_city',
  'parking_state',
  'parking_zipcode',
  'created_at',
]

export const dateFields = [
  'reservation_start_date',
  'reservation_end_date',
  'created_at',
]

export const filterSubmissionOptions = [
  {
    value: 'in_review',
    label: 'In Review',
  },
  {
    value: 'declined',
    label: 'Declined',
  },
  {
    value: 'accepted_and_paid',
    label: 'Accepted',
  },
]

export const columnsToExcludeFromCsv = ['id', 'updated_at']

export const downloadCSVFilename = 'Partner_Customer_Data.csv'

export const requestSubmissionModalWarning = {
  title: '⚠︎ Attention',
  body: 'If you want to download all existing Partner Customer Data, please remove any applied filters. If a filter is applied, only the filtered data will be downloaded.',
}

export const claimsWarningModalActions = onDownloadPress => [
  {
    label: 'Go Back',
    color: 'danger',
    variant: 'solid',
    button_background: 'bg-gold-100',
  },
  {
    label: 'Download',
    color: 'primary',
    variant: 'solid',
    button_background: 'bg-gray-950',
    onPress: onDownloadPress,
  },
]

export const requestSubmissionModalText = {
  title: '✅ Request Submitted',
  body: 'Your request to download the Partner Customer data has been submitted. Kindly visit the Report tab to check the progress of your report download',
}
export const claimsTabModalActions = onReportTabPress => [
  { label: 'Close', color: 'danger', variant: 'light' },
  {
    label: 'Report tab',
    color: 'default',
    variant: 'light',
    button_background: 'bg-gold-100',
    onPress: onReportTabPress,
  },
]

export const typeSubmissionOptions = [
  {
    value: 'daily',
    label: 'Daily',
  },
  {
    value: 'end_of_month',
    label: 'End of Month',
  },
]

export const uploadReportModalActions = onReportTabPress => [
  { label: 'Close', color: 'danger', variant: 'light' },
  {
    label: 'Upload',
    color: 'default',
    variant: 'light',
    button_background: 'bg-gold-100',
    onPress: onReportTabPress,
  },
]
