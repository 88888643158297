import { useForm } from 'react-hook-form'
import { AddFieldModal } from './AddFieldModal'
import React, { useEffect, useState } from 'react'
import { Button, Spinner } from '@nextui-org/react'
import { UploadIcon } from '@heroicons/react/outline'
import { yupResolver } from '@hookform/resolvers/yup'
import { mapFormDataToApi } from './edit-claim-helpers'
import { verifyClaimTemplate } from 'services/partnerService'
import parkguardLogo from 'assets/images/park-guard-logo.png'
import { CustomisedFieldDisplay } from './CustomisedFieldsDisplay'
import { useLocation, Navigate, useParams } from 'react-router-dom'
import { claimFormValidationSchema } from 'validations/claimFormSchema'
import { FormDateRangePicker } from 'components/generics/date-range-picker'
import { FormInput } from 'components/partner/claim-forms/form-generics/FormInputField'
import { claimFormCheckboxOptions } from 'components/partner/claim-forms/form-constants'
import { claimFormUserInstructions } from 'components/partner/claim-forms/form-constants'
import { FormSelect } from 'components/partner/claim-forms/form-generics/FormSelectField'
import { FormDatePicker } from 'components/partner/claim-forms/form-generics/FormDatePicker'
import { FormCheckbox } from 'components/partner/claim-forms/form-generics/FormCheckboxField'
import {
  addNewFormField,
  deleteFormField,
  updateFormField,
} from 'services/adminService'

const RenderDisabledFileUpload = ({ label = '' }) => {
  return (
    <div className="w-full">
      <label className="block font-medium text-gray-500 mb-1">{label}</label>
      <Button
        size="sm"
        startContent={<UploadIcon className="h-5 w-5 text-gray-500" />}
        isDisabled={true}
        className="w-full max-w-80 bg-gold-100"
      >
        Select Files
      </Button>
    </div>
  )
}

export const EditClaimFormTemplate = ({
  setTemplateKey = () => {},
  setFormBgColor = () => {},
}) => {
  const {
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(claimFormValidationSchema),
  })

  const location = useLocation()
  const { url_suffix } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [templateData, setTemplateData] = useState({ fields: [] })
  const [verificationFailed, setVerificationFailed] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editingField, setEditingField] = useState(null)

  const verifyAndGetTemplate = async () => {
    try {
      const response = await verifyClaimTemplate(url_suffix)
      setTemplateData(response.data)
      setTemplateKey(response.data?.access_key)
      setFormBgColor(response.data?.color_theme)
      setIsLoading(false)
    } catch (error) {
      console.error('Verification failed:', error)
      setVerificationFailed(true)
      setIsLoading(false)
    }
  }

  const handleAddField = () => {
    setEditingField(null)
    setIsModalOpen(true)
  }

  const handleEditField = field => {
    setEditingField(field)
    setIsModalOpen(true)
  }

  const handleCancelEdit = () => {
    setEditingField(null)
    setIsModalOpen(false)
  }

  const handleDeleteField = async id => {
    try {
      await deleteFormField(id)
      await verifyAndGetTemplate()
    } catch (error) {
      console.error('Error deleting field:', error)
    }
  }

  const handleSaveField = async newFieldData => {
    try {
      const formData = mapFormDataToApi(newFieldData)

      if (editingField) {
        await updateFormField(editingField.id, formData)
      } else {
        await addNewFormField(templateData.access_key, formData)
      }

      await verifyAndGetTemplate()
    } catch (error) {
      console.error('Error saving/updating field:', error)
    }
    setEditingField(null)
    setIsModalOpen(false)
  }

  useEffect(() => {
    verifyAndGetTemplate()
  }, [location.pathname])

  if (verificationFailed) {
    return <Navigate to="/unauthorized" />
  }

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center py-24">
          <Spinner color="default" size="lg" />
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-start">
            <img src={parkguardLogo} alt="Logo" className="w-30 h-10" />
          </div>

          <div className="p-6 w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Static Disabled Fields */}
              <FormInput
                label="First Name"
                className="col-span-2 sm:col-span-1"
                disabled={true}
              />
              <FormInput
                label="Last Name"
                className="col-span-2 sm:col-span-1"
                disabled={true}
              />
              <FormInput label="Email" className="col-span-2" disabled={true} />
              <FormInput
                label="Mailing Address"
                className="col-span-2"
                disabled={true}
              />
              <FormDateRangePicker
                label="Date Parked (Range)"
                className="col-span-2 sm:col-span-1"
                disabled={true}
              />
              <FormInput
                label="License Plate Number"
                className="col-span-2 sm:col-span-1"
                disabled={true}
              />
              <FormSelect
                label="License Plate State"
                placeholder="Select a state"
                className="col-span-2 sm:col-span-1"
                disabled={true}
              />
              <FormSelect
                label="Type of Damage"
                placeholder="Select damage type"
                className="col-span-2 sm:col-span-1"
                disabled={true}
              />

              <div className="grid grid-cols-1 md:grid-cols-3 gap-6 col-span-2">
                <FormInput label="Car Make" disabled={true} />
                <FormInput label="Car Model" disabled={true} />
                <FormInput label="Car Year" type="number" disabled={true} />
              </div>

              <FormDatePicker
                label="Date Damage Discovered"
                className="col-span-2 sm:col-span-1"
                disabled={true}
              />
              <FormInput
                label="Phone Number"
                placeholder="123-456-7890"
                className="col-span-2 sm:col-span-1"
                disabled={true}
              />
              <FormInput
                label="Address of Parking Facility"
                className="col-span-2"
                disabled={true}
              />

              {/* Dynamic Fields */}
              {templateData.fields.map(field => (
                <CustomisedFieldDisplay
                  key={field.id}
                  field={field}
                  onEdit={handleEditField}
                  onDelete={handleDeleteField}
                  control={control}
                />
              ))}

              <Button
                onClick={handleAddField}
                className="col-span-2 mt-4 max-w-48"
              >
                Add New Field
              </Button>

              <AddFieldModal
                isOpen={isModalOpen}
                onClose={handleCancelEdit}
                onSave={handleSaveField}
                initialData={editingField}
              />

              {/* Static Disabled Fields */}
              <RenderDisabledFileUpload label="Upload Photo of Damage" />
              <RenderDisabledFileUpload label="Upload Receipt" />
              <div className="flex items-center justify-center col-span-2">
                <RenderDisabledFileUpload label="Upload Additional Documents" />
              </div>

              <FormCheckbox
                control={control}
                name="legalFormClause"
                label={claimFormCheckboxOptions.legalFormClause}
                errors={errors}
                className="col-span-2 text-justify"
                disabled={true}
              />
              <FormCheckbox
                control={control}
                name="termsClause"
                label={claimFormCheckboxOptions.adminTermsAndConditions}
                errors={errors}
                className="col-span-2 text-justify"
                disabled={true}
              />

              <span className="text-xs text-gray-500 col-span-2 px-3 text-justify">
                {claimFormUserInstructions}
              </span>

              <div className="flex col-span-2 item-center justify-center p-2">
                <Button
                  type="submit"
                  color="primary"
                  variant="solid"
                  className="w-full min-w-[300px] max-w-[300px] min-h-[45px] bg-gray-950"
                  isDisabled={true}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
