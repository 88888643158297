import { uploadReport } from 'services/partnerService'

export const handleUploadCsv = async (
  type,
  csvFile,
  setIsUploading,
  handleSearch,
  showToastCallback,
) => {
  try {
    setIsUploading(true)
    const response = await uploadReport(csvFile, type)
    if (response.status === 201) {
      handleSearch('')
      showToastCallback('CSV uploaded successfully!', 'success')
    }
  } catch (error) {
    showToastCallback('Something went wrong!', 'error')
  } finally {
    setIsUploading(false)
  }
}
