import React from 'react'

export const SectionCard = ({
  title,
  details = null,
  renderContent = null,
  children,
}) => (
  <div className="p-6">
    <h2 className="text-xl font-bold text-gray-800 mb-4 text-center pb-4">
      {title}
    </h2>
    {details && details.length > 0 ? (
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {details.map(({ label, value }, index) => (
          <div key={index}>
            <strong className="text-gray-600 text-sm">{label}:</strong>
            <p className="text-gray-800">{value || 'N/A'}</p>
          </div>
        ))}
      </div>
    ) : renderContent ? (
      renderContent
    ) : children ? (
      <div className="grid grid-cols-1 gap-4">{children}</div>
    ) : (
      <p className="text-gray-500">No details available.</p>
    )}
  </div>
)
