import moment from 'moment'

export const truncateText = (text, limit) => {
  if (text.length < limit) {
    return text
  }
  return text.slice(0, limit - 3) + '...'
}

export const formatDataForTable = (apiData, requiredColumns) => {
  const columns = requiredColumns.map(col => ({
    uid: col,
    name: col.replace(/_/g, ' ').toUpperCase(),
  }))

  const data = apiData?.map(item => {
    const row = {}
    requiredColumns.forEach(col => {
      row[col] =
        item[col] !== null && item[col] !== undefined ? item[col] : 'N/A'
    })
    return row
  })

  return { columns, data }
}

export const copyToClipboard = async (hostname = '', subdomain = '') => {
  hostname = hostname ? hostname : process.env.REACT_APP_CLIENT_URL
  const url = `${hostname}${subdomain}`
  try {
    await navigator.clipboard.writeText(url)
  } catch (err) {
    console.error('Failed to copy: ', err)
  }
}

export const formatDateToUTC = date => {
  if (!moment(date, moment.ISO_8601, true).isValid()) {
    return 'N/A'
  }
  return moment.utc(date).format('MMMM D, YYYY')
}

export const formatDataDateByKey = (dataArray, key) => {
  return dataArray.map(item => ({
    ...item,
    [key]: item[key] ? formatDateToUTC(item[key]) : item[key],
  }))
}

export const extractKeyAndFlatObject = (dataArray, key, valueKey) => {
  return dataArray.map(item => {
    if (item[key] && item[key][valueKey]) {
      return {
        ...item,
        [key]: item[key][valueKey],
      }
    }
    return item
  })
}

export const formatAndCapitalize = words => {
  if (typeof words !== 'string') {
    return ''
  }

  return words
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const formatDataDatesByList = (dataArray, dateFields) => {
  return dataArray?.map(item => {
    const formattedItem = { ...item }
    dateFields.forEach(field => {
      if (item[field]) {
        formattedItem[field] = formatDateToUTC(item[field])
      }
    })
    return formattedItem
  })
}

export const formatFileSize = size => {
  if (size < 1024) return `${size} B`
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`
  return `${(size / (1024 * 1024)).toFixed(2)} MB`
}

export const getFormFieldError = (errors, name) => {
  return name.split('.').reduce((acc, part) => acc?.[part], errors)
}

export const formatDateParams = (dateStart, dateEnd) => {
  const params = []
  if (dateStart) params.push(`date_start=${dateStart}`)
  if (dateEnd) params.push(`date_end=${dateEnd}`)
  return params
}

export const formatDateRange = dateRange => {
  if (!Array.isArray(dateRange) || dateRange.length !== 2) {
    throw new Error(
      'Invalid date range format. Expected an array with two dates.',
    )
  }

  const [start, end] = dateRange.map(date => {
    if (date === null) return null
    const parsedDate = new Date(date)
    return parsedDate.toISOString().split('T')[0]
  })

  return {
    date_start: start,
    date_end: end,
  }
}

export const formatPartnerList = data => {
  if (!Array.isArray(data) || data.length === 0) {
    return []
  }

  return data.map(item => ({
    value: item.id?.toString() || '',
    label: item.name?.toLowerCase() || '',
  }))
}
